img {
  vertical-align: bottom;
  max-width: 100%;
}

@media screen and (min-width: 1001px) {
  a:hover {
    opacity: .7;
  }
}

.contents {
  max-width: 1000px;
  margin: 0 auto;
}

/*---------- movie ----------*/
.movie {
  padding: 0 11.5%;
  box-sizing: border-box;
}

.movie video {
  width: 100%;
}

/*---------- voice ----------*/
.voice {
  position: relative;
  background: url("../../img/index/img44.jpg") no-repeat center top;
  background-size: 100% auto;
  height: 920px;
}

@media screen and (max-width: 1000px) {
  .voice {
    height: 92vw;
  }
}

.voice__slide-wrap {
  padding-top: 254px;
}

@media screen and (max-width: 1000px) {
  .voice__slide-wrap {
    padding-top: 25.4vw;
  }
}

.voice .swiper-container .swiper-slide {
  width: 65%;
  /*background: rgba(121,190,193,0.8);*/
  border-radius: 15px;
  overflow: hidden;
}

/*---------- cta ----------*/
.cta {
  background: #f8f8f8;
  padding-bottom: 11%;
}

.cta__regular {
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
}

.cta__normal {
  max-width: 820px;
  width: 82%;
  margin: 0 auto;
}

.cta .accordion {
  background: #fff;
  padding: 0 7% 9%;
  box-sizing: border-box;
}

.cta .accordion .ttl {
  width: 100%;
  background: #79bec1;
  padding: 2.32558%;
  cursor: pointer;
  position: relative;
  transition: all .3s;
  margin: 0 auto;
  border-radius: 3px;
  box-sizing: border-box;
}

.cta .accordion .ttl:before, .cta .accordion .ttl:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
}

.cta .accordion .ttl:before {
  width: 40px;
  height: 3px;
  right: 37px;
}

@media screen and (max-width: 1000px) {
  .cta .accordion .ttl:before {
    width: 4vw;
    height: 0.3vw;
    right: 3.7vw;
  }
}

.cta .accordion .ttl:after {
  width: 3px;
  height: 40px;
  right: 56px;
}

@media screen and (max-width: 1000px) {
  .cta .accordion .ttl:after {
    width: 0.3vw;
    height: 4vw;
    right: 5.6vw;
  }
}

.cta .accordion .ttl.show:after {
  content: none;
}

.cta .accordion .ttl .txt {
  width: 53.02326%;
  margin: 0 auto;
  display: block;
}

.cta .accordion .detail {
  display: none;
  width: 100%;
  margin: 0 auto;
  font-size: 18px;
  line-height: 2;
  position: relative;
  box-sizing: border-box;
  padding-top: 3.48837%;
  text-align: left;
}

@media screen and (max-width: 1000px) {
  .cta .accordion .detail {
    font-size: 3.2vw;
  }
}

/*---------- material ----------*/
.material {
  margin-top: 37px;
}

@media screen and (max-width: 1000px) {
  .material {
    margin-top: 3.7vw;
  }
}
