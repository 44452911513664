// 設定 ---------------------------------------------
$break-point: 1000px;
@mixin min_screen($bp) {
	@media screen and (min-width: $bp) {
		@content;
	}
}

@mixin max_screen($bp) {
	@media screen and (max-width: $bp) {
		@content;
	}
}

@function vw($pixelsVw) {
	$baseWidth : 1000;
	@return $pixelsVw / $baseWidth * 100vw;
}

img {
	vertical-align: bottom;
	max-width: 100%;
}

@media screen and (min-width: 1001px) {
	a:hover{
		opacity: .7;
	}
}

// style ---------------------------------------------
.contents{
	max-width: 1000px;
	margin: 0 auto;
}

/*---------- movie ----------*/
.movie{
	padding: 0 percentage(115 / 1000);
	box-sizing: border-box;

	video{
		width: 100%;
	}
}

/*---------- voice ----------*/
.voice{
	position: relative;
	background: url("../../img/index/img44.jpg") no-repeat center top;
	background-size: 100% auto;
	height: 920px;

	@include max_screen($break-point) {
		height: vw(920);
	}

	&__slide-wrap{
		padding-top: 254px;

		@include max_screen($break-point) {
			padding-top: vw(254);
		}
	}

	.swiper-container{
		.swiper-slide{
			width: percentage(650 / 1000);
			/*background: rgba(121,190,193,0.8);*/
			border-radius: 15px;
			overflow: hidden;
		}
	}
}

/*---------- cta ----------*/
.cta{
	background: #f8f8f8;
	padding-bottom: percentage(110 / 1000);

	&__regular{
		max-width: 900px;
		width: percentage(900/ 1000);
		margin: 0 auto;
	}

	&__normal{
		max-width: 820px;
		width: percentage(820/ 1000);
		margin: 0 auto;
	}

	.accordion{
		background: #fff;
		padding: 0 percentage(70 /1000) percentage(90 /1000);
		box-sizing: border-box;

		.ttl {
			width: 100%;
			background: #79bec1;
			padding: percentage(20 / 860);
			cursor: pointer;
			position: relative;
			transition: all .3s;
			margin: 0 auto;
			border-radius: 3px;
			box-sizing: border-box;

			&:before,
			&:after {
				position: absolute;
				content: "";
				top: 50%;
				transform: translateY(-50%);
				background: #fff;
			}

			&:before {
				width: 40px;
				height: 3px;
				right: 37px;

				@include max_screen($break-point) {
					width: vw(40);
					height: vw(3);
					right: vw(37);
				}
			}

			&:after {
				width: 3px;
				height: 40px;
				right: 56px;

				@include max_screen($break-point) {
					width: vw(3);
					height: vw(40);
					right: vw(56);
				}
			}

			&.show {
				&:after {
					content: none;
				}
			}

			.txt{
				width: percentage(456 /860);
				margin: 0 auto;
				display: block;
			}
		}

		.detail {
			display: none;
			width: 100%;
			margin: 0 auto;
			font-size: 18px;
			line-height: (72/36);
			position: relative;
			box-sizing: border-box;
			padding-top: percentage(30 /860);
			text-align: left;

			@include max_screen($break-point) {
				font-size: vw(32);
			}
		}
	}
}

/*---------- material ----------*/
.material{
	margin-top: 37px;

	@include max_screen($break-point) {
		margin-top: vw(37);
	}
}
